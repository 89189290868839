@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.32px;
  color: $black;
}
.wrapperBottom {
  display: flex;
  flex-direction: column;
  background-color: $white;
}
.leftContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  min-width: 500px;
  border-bottom: 1px solid $light-grey;
}
.middleContent {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  border: 1px solid red;
}
.wrapperForm {
  display: flex;
  flex-direction: column;
  // flex: 1;
  // min-width: 100%;
  // border: 1px solid yellow;
}
.header {
  display: flex;
  position: relative;
}
.wrapperName {
  display: grid;
  grid-template-columns: 1fr minmax(170px, 1fr);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.24px;
  color: $black;
  gap: 10px;
}

.description {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.wrapperDescription {
  display: flex;
  gap: 8px;
}
.reduction {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  color: $primary-grey;
}
.value {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.status {
  width: max-content;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  white-space: nowrap;
  padding: 4px 12px;
  background-color: $secondary-light-green;
  color: $light-green;
  height: 24px;
}
.titleInvitation {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: $black;
}
.wrapperInvitation {
  display: flex;
  flex-direction: column;
  max-width: 650px;
  gap: 8px;
  padding: 32px;
  width: 100%;
}
.wrapperInput {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.label {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $primary-dark-grey;
}
.inforamtion {
  display: flex;
  gap: 14px;
  align-items: center;
}
.iconInformation {
  margin-top: 8px;
}
.textInformation {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $deep-grey;
}
.wrapperAgreement {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  gap: 8px;
  border-top: 1px solid $light-grey;
  border-bottom: 1px solid $light-grey;
}
.button {
  display: flex;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  height: 40px;
  text-transform: none;
}
.wrapperButton {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 16px 24px;
  justify-content: flex-end;
}
.line {
  border-top: 1px solid $light-grey;
}
.button_back {
  display: flex;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  height: 40px;
  text-transform: none;
  background-color: $white;
  color: $brand;
  border: 1px solid $brand;
}

.status-red {
  background-color: $secondary-red;
  color: $white;
}
