@import 'styles/variables';

.wrapperDescription {
  display: flex;
  gap: 8px;
}
.reduction {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  color: $primary-grey;
}
.value {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
