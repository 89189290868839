* {
  box-sizing: border-box;
}

body,
h1,
h2,
h3 {
  margin: 0;
}

p {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
