@import 'styles/variables';

.wrapperColumn {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  
  @media (min-width: $desktop) {
    gap: 0px;
    
  }

  .column {
    flex: 1;
    min-width: 300px;
    box-sizing: border-box;
    
    @media (min-width: $desktop) {
      &:not(:first-child) {
        padding: 0px 48px;
      }
      
      &:nth-child(2) {
        border-inline: 1px solid $tertiary-light-grey;
      }
    }
  }
}
.wrapperBody {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.contentBody {
  display: flex;
  flex-direction: column;
  gap: 4px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.wrapperHeader {
  margin-bottom: 12px;
}
.title {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: $primary-dark-grey;
}
.label {
  
  color: $primary-grey;
}
.desc {
  color: $black;
}

.column-2 {
  padding-left: 48px;
  border-inline: 1px solid $tertiary-light-grey;
}

.column-3 {
  padding: 0px 0px;
}