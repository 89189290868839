@import 'styles/variables';

.container {
  display: flex;
  align-items: center;
  width: 100%;
}
.wrapper {
  width: 100%;
}
.content {
  width: 100%;
}
.tabs {
  position: relative;
  display: flex;
  flex: 1;
  gap: 32px;

  border-bottom: 1px solid $secondary-dark-grey;
  width: 100%;
}
.tab {
  cursor: pointer;
  padding-bottom: 16px;
  position: relative;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $primary-grey;
}
.activeTab {
  color: $blue;
  border-bottom: 4px solid $blue;
}
.label {
}
.count {
  position: absolute;
  top: -5px;
  right: -15px;
  text-align: center;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.12px;
  padding-inline: 3px;
  color: $white;
  background: $light-green;
}
.wrapperButton {
  position: absolute;
  top: 0;
  right: 0;
}
.button {
  text-transform: none;
}
.disabledTab {
  pointer-events: none;
}