@import 'styles/variables';

.root {
  padding: 4px 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.draft {
  color: $light-white;
  background-color: $primary-grey;
}
.active {
  color: $light-green;
  background-color: $secondary-light-green;
}
.blocked {
  color: $secondary-red;
  background-color: $secondary-light-red;
}
.delete {
  color: $secondary-red;
  background-color: $secondary-light-red;
}
.expectation {
  color: $secondary-blue;
  background-color: $secondary-light-blue;
}
.secondary-expectation {
  color: $secondary-yellow;
  background-color: $secondary-light-yellow;
}
.completed {
  color: $primary-grey;
  background-color: $light-white;
}
