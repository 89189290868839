@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Thin"), local("Gilroy-Thin"),
    url("/assets/fonts/GilroyThin/font.woff2") format("woff2"),
    url("/assets/fonts/GilroyThin/font.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy UltraLight"), local("Gilroy-UltraLight"),
    url("/assets/fonts/GilroyUltraLight/font.woff2") format("woff2"),
    url("/assets/fonts/GilroyUltraLight/font.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Light"), local("Gilroy-Light"),
    url("/assets/fonts/GilroyLight/font.woff2") format("woff2"),
    url("/assets/fonts/GilroyLight/font.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Regular"), local("Gilroy-Regular"),
    url("/assets/fonts/GilroyRegular/font.woff2") format("woff2"),
    url("/assets/fonts/GilroyRegular/font.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Regular Italic"), local("Gilroy-RegularItalic"),
    url("/assets/fonts/GilroyRegularItalic/font.woff2") format("woff2"),
    url("/assets/fonts/GilroyRegularItalic/font.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Medium"), local("Gilroy-Medium"),
    url("/assets/fonts/GilroyMedium/font.woff2") format("woff2"),
    url("/assets/fonts/GilroyMedium/font.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Semibold"), local("Gilroy-Semibold"),
    url("/assets/fonts/GilroySemiBold/font.woff2") format("woff2"),
    url("/assets/fonts/GilroySemiBold/font.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy Bold"), local("Gilroy-Bold"),
    url("/assets/fonts/GilroyBold/font.woff2") format("woff2"),
    url("/assets/fonts/GilroyBold/font.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

* {
  font-family: Gilroy, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}