@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.containerTop {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.32px;
  color: $black;
}
.wrapperButton {
  flex: 0.5;
  display: flex;
}
.button {
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  justify-content: center;
  text-transform: none;
}
.wrapperSearch {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 16px 24px;
  background-color: $white;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.wrapperBottom {
  padding: 32px;
  display: flex;
  height: 40vh;
  align-items: center;
  justify-content: center;
  background-color: $secondary-light-grey;
}
.wrapperContent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.notFound {
  max-width: 371px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.textNotFound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  color: $primary-grey;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}
.result {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.highlight {
  background-color: $yellow;
}
