@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.32px;
  color: $black;
}

.button {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; 
  height: 32px;
  padding: 4px 12px;
  text-transform: none;
  display: flex;
  align-items: center;
  color: $brand;
  background-color: transparent;
  border: 1px solid $brand;

  svg path {
    fill: $brand;
  }
}

.wrapper-content {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.container-edo {
  padding: 16px;
  background-color: $white;
}

.content-edo {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;

  &:hover {
    background-color: $primary-light-blue;
  }
}

.header-edo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.name {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.24px;
  color: $black;
}

.wrapper-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.attr {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $secondary-primary-dark-grey;
}

.wrapper-field {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 560px;
}

.content-modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
}