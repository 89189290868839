@import 'styles/variables';

.root {
  flex: 1;
  padding: 0 100px;
  display: flex;
  flex-direction: column;
}

.content {
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  @media (min-width: $desktop) {
    flex-direction: row;
  }
}

.firstBlock {
  flex: 1;
  gap: 16px;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
}

.header {
  font-size: 80px;
  font-weight: lighter;
  text-transform: uppercase;
}

.subtitle {
  color: var(--color-primary-black, #050e1e);

  font-family: Gilroy;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  max-width: 35%;
  letter-spacing: 0.32px;
  white-space: pre-wrap;
}

.description {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $black;
}

.preview-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: $white;
}

.secondBlock {
  gap: 32px;
  top: 50px;
  right: 2%;
  height: 90%;
  width: 850px;
  padding: 32px;
  display: flex;
  position: fixed;
  overflow-y: auto;
  margin-bottom: 80px;
  padding-bottom: 30px;
  flex-direction: column;
  align-items: flex-start;

  background: $white;
  box-shadow: 4px 4px 56px 0px rgba(66, 66, 132, 0.15);
}

.text {
  font-size: 20px;
  line-height: 40px;
  margin-top: 40px;
  max-width: 756px;
}

.selector {
  margin-top: 40px;
}

.bottom {
  width: 100%;
  max-width: 480px;
  margin: 40px auto 65px;
  @media (min-width: $desktop) {
    margin: 0 auto -114px;
  }
}

.buttonContainer {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.buttonWrapper {
  gap: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.full-width {
  width: 100%;
}

.button {
  display: flex;
  flex: 1;
}
.buttonSign {
  display: flex;
  flex: 2;
}

.recoverButton {
  margin-top: 27px;
  background: #f2f2f2;
  @media (min-width: $desktop) {
    background: white;
  }
}
