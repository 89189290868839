@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.wrapper-content {
  display: grid;
  grid-template-columns: minmax(300px, 495px) minmax(400px, 1fr);
  background-color: $white;
  min-height: 646px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  background-color: $white;
  border-top: 1px solid $light-white;
  gap: 12px;
}

.button {
  height: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
}
