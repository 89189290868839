@import 'styles/variables';

.root {
  flex: 1;
  margin-top: -20px;
  padding: 0 100px;
  display: flex;
  flex-direction: column;
}

.content {
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  @media (min-width: $desktop) {
    flex-direction: row;
  }
}

.firstBlock {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.loadingBlock {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.header {
  font-size: 80px;
  font-weight: lighter;
  margin-bottom: 40px;
  text-transform: uppercase;

  display: none;
  @media (min-width: $desktop) {
    display: block;
  }
}

.title {
  font-size: 40px;

  font-weight: normal;
  text-transform: none;
  @media (min-width: $desktop) {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.secondBlock {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 40%;
  display: flex;
  padding: 56px;
  padding-bottom: 30px;
  height: calc(100% - 100px);
  align-items: flex-start;
  position: absolute;
  top: 50px;
  right: 10%;

  background: $white;
  box-shadow: 4px 4px 56px 0px rgba(66, 66, 132, 0.15);
}

.info {
  font-size: 20px;
  line-height: 30px;
}

.bottom {
  width: 100%;
  padding-bottom: 56px;

  @media (min-width: $desktop) {
    margin: 0 auto -30px;
  }
}

.bottomSigned {
  width: 100%;
  max-width: 480px;
  padding-bottom: 0px;

  margin: 40px auto 65px;
  @media (min-width: $desktop) {
    margin: 0 auto -22px;
  }
}

.button {
  width: 100%;
  font-family: Gilroy;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.24px;
  height: 48px;
  text-transform: none;
}

.status {
  color: $secondary-red;
}
.thirBlock {
  flex: 1;
  position: relative;
}
.thirStep {
  position: absolute;
  left: 42%;
}

.document {
  padding: 64px 0px;
}

.checkboxContainer {
  flex: 1;
  gap: 4px;
  display: flex;
  flex-direction: row;
}

.checkbox {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  flex: 1 0 0;
  overflow: hidden;
  color: $secondary-primary-dark-grey;
  text-overflow: ellipsis;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
}

.checkbox:hover {
  cursor: pointer;
}

.wrapperCompany {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.headerOffer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.infoSign {
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $secondary-primary-dark-grey;
}

.nameCompany {
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $black;
}

.wrapperContent {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.12px;
}

.content {
  color: $black;
}

.size {
  color: $secondary-primary-dark-grey;
}

.view {
  cursor: pointer;
  color: $brand;
}

.wrapperFile {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 8px;
  gap: 10px;
  background-color: $secondary-light-grey;
}

.wrapperInfo {
  display: flex;
  align-items: center;
  position: relative;
  gap: 20px;
  position: relative;
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 50px;
  background-color: $primary-grey;
}

.wrapperUser {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.terms,
.username {
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $black;
}
