@import 'styles/variables';

.root {
  flex: 1;
  padding: 0 100px;
  display: flex;
  flex-direction: column;
}

.content {
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  @media (min-width: $desktop) {
    flex-direction: row;
  }
}

.firstBlock {
  flex: 1;
  gap: 16px;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
}

.header {
  font-size: 80px;
  font-weight: lighter;
  text-transform: uppercase;
}

.entranceContainer {
  width: 50%;
  display: flex;
  padding-right: 132px;
  align-items: flex-end;
  justify-content: flex-end;
}

.entrance {
  margin-top: 48px;
  font-size: 80px;
  font-weight: bold;
  color: $brand;
  & > * {
    display: inline-block;
  }
  svg {
    width: 88px;
    height: 88px;
    margin-left: 18px;
  }

  display: none;
  @media (min-width: $desktop) {
    display: flex;
  }
  align-items: center;
}

.subtitle {
  color: var(--color-primary-black, #050e1e);

  font-family: Gilroy;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  max-width: 47%;
  letter-spacing: 0.32px;
  white-space: pre-wrap;
}

.description {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $black;
}

.secondBlock {
  gap: 32px;
  width: 40%;
  display: flex;
  padding: 56px;
  padding-bottom: 30px;
  height: calc(100% - 100px);
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 50px;
  right: 10%;

  background: $white;
  box-shadow: 4px 4px 56px 0px rgba(66, 66, 132, 0.15);
}

.text {
  font-size: 20px;
  line-height: 40px;
  margin-top: 40px;
  max-width: 756px;
}

.selector {
  margin-top: 40px;
}

.bottom {
  width: 100%;
  max-width: 480px;
  margin: 40px auto 65px;
  @media (min-width: $desktop) {
    margin: 0 auto -114px;
  }
}

.buttonContainer {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.full-width {
  width: 100%;
}

.button {
  width: 100%;
}

.recoverButton {
  margin-top: 27px;
  background: #f2f2f2;
  @media (min-width: $desktop) {
    background: white;
  }
}

.wrapperForm {
  width: 100%;
  max-height: 50dvh;
  overflow-y: auto;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $light-grey;
    border-radius: 8px;
  }
}

@media (min-width: 1400px) {
  .wrapperForm {
    max-height: 67dvh;
  }
}

.wrapperIcon {
  display: flex;
  align-items: center;
  gap: 8px;

  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $primary-grey;
}

.wrapperIcon svg path {
  fill: $secondary-primary-dark-grey;
}

.wrapperDesc {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.notFound {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
