@import 'styles/variables';

.user-container {
  padding: 16px;
  background-color: $white;
}
.user-content {
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 16px;
  cursor: pointer;
  &:hover {
    background-color: $primary-light-blue;
  }
}

.active {
  background-color: $primary-light-blue;
}

.line {
  padding: 16px 16px 32px 16px;
  border-bottom: 1px solid $light-grey;
  cursor: pointer;
  &:hover {
    background-color: $primary-light-blue;
  }
}
.user-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.date {
  display: flex;
  gap: 4px;
  color: $primary-grey;
}
.status {
  padding: 4px 12px;
  color: $light-green;
  background-color: $secondary-light-green;
}
.user-main {
  margin-top: 8px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $black;
  line-height: 24px;
  letter-spacing: 0.7px;
}
.description {
  display: flex;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.user-footer {
  margin-top: 4px;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}
.reduction {
  text-transform: uppercase;
  color: $primary-grey;
}
.value {
  color: $black;
}
