@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.wrapper-content {
  padding: 32px;
  background-color: $white;
}

.breadcrumbs {
  color: $primary-grey;
}

.wrapper-header {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.header-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-footer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.wrapper-info {
  display: flex;
  align-items: center;
  gap: 12px;
}
.contract-type {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $primary-grey;
  padding: 4px 12px;
  border: 1px solid $primary-grey;
}
.name {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: $black;
}
.wrapper-button {
  display: flex;
  align-items: center;
  gap: 12px;
}
.button {
  display: flex;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  height: 40px;
  box-shadow: 0px 1px 3px 0px rgba(17, 38, 60, 0.04);
  text-transform: none;

  path {
    fill: $green;
  }
}

.wrapper-more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  background-color: $light-grey;
}
.dropdown-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
