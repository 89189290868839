@import 'styles/variables';

.section-principal {
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  border-bottom: 1px solid $light-grey;
}
.row {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  
  @media (min-width: 1500px) {
    flex-direction: row;
  }
}
.first {
  flex: 0 0 24px;
  
  @media (min-width: 1500px) {
    flex: 0 0 397px;
  }
}
.wrapper-title-column {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.title-column {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $black;
}
.desc-column {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: $black;
}
.second-column {
  margin-top: 16px;
  
  @media (min-width: 1700px) {
    margin-top: 40px;
  }
}
.container-abbr {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
  width: 100%;
  
  @media (min-width: 1700px) {
    gap: 24px;
  }
}
.abbr-row {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.title-abbr {
  color: $primary-grey;
}
.content-abbr {
  color: $black;
}
.wrapper-first {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}
.wrapper-abbr {
  display: flex;
  flex-direction: column;
  gap: 4px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.wrapper-second {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media (min-width: 1700px) {
    gap: 24px;
  }
}