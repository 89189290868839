/* DropdownItem.module.scss */
@import 'styles/variables';

.dropdownItem {
  padding: 10px;
  min-width: 200px;
  cursor: pointer;
}

.container {
  display: flex;
  align-items: center;
  margin: 4px 0px;
  padding: 0px 12px;
  width: 100%;

  &:hover {
    background-color: #f0f0f0;
  }
}

.icon {
  display: flex;
  align-items: center;
}

.icon svg path {
  fill: $blue;
}
