@import '../../dynamic/colors/colors.scss';

$brand: #ff3e00;
$green: #5EC800;
$red: #d60000;
$grey: #dedede;
$light-blue: #abbae2;
$blue: #2d2eb7;
$deep-blue: #000073;
$white: #ffffff;
$light-white: #E0E5EC;
$primary-dark-grey: #546A8C;
$light-grey: #C4CED7;
$deep-grey: #8997AE;
$black: #050E1E;
$light-green: #35A388;
$primary-grey: #7F90AC;
$primary-light-blue: #ECF3FD;
$secondary-red: #FF0B37;
$secondary-light-red: #FCDDE2;
$secondary-light-grey:#F0F3F6;
$secondary-primary-grey:#E6E9EE;
$tertiary-light-grey: #d3d9e4;
$yellow: #FDE047;
$secondary-light-green: #D9F4E6;
$blue-grey: #344659;
$secondary-light-blue: #BFD8FF;
$secondary-blue: #4B4DF8;
$dark-dark-68: #586C8C;
$dark-dark-48: #8997AE;
$secondary-dark-grey: rgba(84, 106, 140, 0.2);
$secondary-yellow: #FB8832;
$secondary-light-yellow: #FFE0B1;
$secondary-black: rgba(50, 59, 75, 0.4);
$secondary-light-black: rgba(79, 98, 129, 0.2);
$tertiary-light-black: rgba(127, 144, 172, 0.16);
$secondary-primary-dark-grey: #4F6281;
$dark: #333;
$secondary-green: #35A388;
$primary-light-grey: #BECADC;
$stroke-normal-grey: #C4CED7;
$tertiary-light-yellow: #FCF6EF;
$background-light-grey-2: #F8F9FB;