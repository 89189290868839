@import 'styles/variables';

.container {
  margin: 24px 0px;
}

.wrapper-content {
  display: grid;
  grid-template-columns: minmax(200px, 395px) minmax(400px, 1fr);
  min-height: 295px;
}

.left-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-right: 1px solid $secondary-dark-grey;
}

.wrapper-bottom {
  display: flex;
  align-items: center;
  gap: 32px;
}

.wrapper-attributes {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.title {
  color: $primary-grey;
}

.text {
  color: $black;
}

.right-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-left: 48px;
}