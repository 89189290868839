@import 'styles/variables';

.section-representative {
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
  border-bottom: 1px solid $light-grey;
}
.wrapper-title-representative {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.desc-representative {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $black
}
.title-representative {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $black;
}
.row {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  
  @media (min-width: 1500px) {
    flex-direction: row;
  }
}
.first {
  flex: 0 0 24px;
  
  @media (min-width: 1500px) {
    flex: 0 0 397px;
  }
}
.wrapper-first {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}
.wrapper-abbr {
  display: flex;
  flex-direction: column;
  gap: 4px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.title-abbr {
  color: $primary-grey;
}
.content-abbr {
  color: $black;
}
.column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.representative-second-column {
  display: flex;
  justify-content: flex-end;
}
.wrapper-second {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media (min-width: 1700px) {
    gap: 24px;
  }
}
