@import 'styles/variables';

.step-container {
  padding: 16px;
  border-bottom: 1px solid $light-white;
}
.wrapper-step {
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;

  &:hover {
    background-color: $primary-light-blue;
  }
}
.current {
  background-color: $primary-light-blue;
}
.wrapper-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.step {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $primary-grey;
}
.name {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.16px;
  color: $secondary-primary-dark-grey;
}
.checkbox-mark {
  position: relative;
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: 2px solid $blue;
  margin-left: 3px;
  margin-right: 16px;
  transition: background-color 0.145s linear;

  .checkbox-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &.checked {
    background-color: $blue;
    border-color: $blue;
    transition: background-color 0.145s linear;

    .checkbox-icon-container > svg {
      opacity: 1;
      transition: opacity 0.2s linear;

      path, rect {
        fill: $white;
      }
    }
  }
  &:not(.checked) {
    background-color: $white;
    border-color: $blue;
    transition: background-color 0.145s linear;

    .checkbox-icon-container > svg {
      opacity: 1;
      transition: opacity 0.2s linear;

      path, rect {
        fill: $white;
      }
    }
  }
}