@import 'styles/variables';

.container,
.container-authority {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 623px;
}
.button {
  height: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
  background-color: transparent;
  border: 1px solid $brand;
  color: $brand;
  width: 100%;

  path,
  rect {
    fill: $white;
  }
}
.wrapper-info {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 0px;
}
