@import 'styles/variables';

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $secondary-black;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in, background 0.3s ease-in,
  visibility 0.3s ease-in;
}
.container.open {
  visibility: visible;
  opacity: 1;
}

.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 612px;
  height: 100vh;
  background-color: $white;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 100;
  overflow-x: hidden;
}
.wrapper.open {
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 24px;
  border-bottom: 1px solid $secondary-primary-grey;
}
.close {
  cursor: pointer;
}
.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.24px;
  color: $black;
}
.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  padding: 24px;
}
.wrapper-select {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.label {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $secondary-primary-dark-grey;
}
.button {
  height: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
}
.button-back {
  background-color: $white;
  color: $brand;
  border: 2px solid $brand;
}
.footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  width: 100%;
  padding: 24px;
  border-top: 1px solid $secondary-primary-grey;
}
