@import 'styles/variables';

.section-powers-representative {
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 24px 0px;
  border-bottom: 1px solid $light-grey;
}
.title-representative {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $black;
}
.column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.wrapper-powers-representative {
  display: flex;
  flex-direction: column;
  gap: 4px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $black;
}
.wrapper-recalled {
  display: flex;
  flex-direction: column;
  gap: 8px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $black
}