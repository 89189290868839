@import 'styles/variables';

.title {
  color: $black;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.32px;
  margin-bottom: 40px;
}
.button {
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  height: 40px;
  padding: 8px 12px;
  text-transform: none;
  white-space: nowrap;
}
.headerContainer {
  margin: 40px 0px;
}
.icon {
  width: 16px;
  height: 16px;
}
