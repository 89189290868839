@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.32px;
}
.button {
  display: flex;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  height: 40px;
  box-shadow: 0px 1px 3px 0px rgba(17, 38, 60, 0.04);
  text-transform: none;
}
