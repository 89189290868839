@import 'styles/variables';

.breadcrumbs {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.breadcrumbs-item {
  display: flex;
  align-items: center;
  position: relative;
  color: $primary-grey;
}
.breadcrumbs-item:not(:last-child)::after {
  content: '/';
  width: 10px;
  height: 20px;
  display: block;
  position: absolute;
  top: 50%;
  right: -7px;
  transform: translate(0, -50%);
}
.breadcrumbs-item:not(:last-child) {
  cursor: pointer;
}
.link {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.1s ease;
  white-space: nowrap;
}

button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $primary-grey;
  cursor: pointer;
}