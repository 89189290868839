@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
}

.header {
  display: flex;
  align-items: center;
  gap: 32px;
}

.wrapper-attributes {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title { 
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $secondary-primary-dark-grey;
}

.desc {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $black
}

.wrapper-authority {
  display: flex;
  flex-direction: column;
  gap: 16px;
}