@import 'styles/variables';

.container {
  padding: 32px;
  min-height: 594px;
  border-left: 1px solid $light-white;
}

.notFoundContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
