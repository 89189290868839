@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.containerTable {
  background-color: $white;
}
.wrapper {
  padding: 32px 32px 0px 32px;
}
.title {
  color: $blue;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.32px;
  text-transform: uppercase;
}
.line {
  height: 1px;
  padding-top: 16px;
  border-bottom: 1px solid $light-grey;
}
.wrapperInfo {
  display: flex;
  margin-top: 16px;
  gap: 48px;
}
.info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.text {
  white-space: nowrap;
  color: $primary-dark-grey;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.count {
  color: $black;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px
}
.icon {
  display: flex;
  justify-content: flex-end;
  padding: 12px;
}