@import 'styles/variables';

.root {
  flex: 1;
  margin-top: -20px;
  padding: 0 100px;
  display: flex;
  flex-direction: column;
}

.content {
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  @media (min-width: $desktop) {
    flex-direction: row;
  }
}

.firstBlock {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.loadingBlock {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.header {
  font-size: 80px;
  font-weight: lighter;
  margin-bottom: 40px;
  text-transform: uppercase;

  display: none;
  @media (min-width: $desktop) {
    display: block;
  }
}

.title {
  font-size: 40px;

  font-weight: normal;
  text-transform: none;
  @media (min-width: $desktop) {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.secondBlock {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.info {
  font-size: 20px;
  line-height: 30px;
  margin-top: 90px;
  @media (min-width: $desktop) {
    margin-top: 60px;
  }
}

.offerInfo {
  margin-top: 26px;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 80px;
  row-gap: 40px;
  max-width: 500px;
  span {
    font-size: 20px;
    line-height: 30px;
    &:nth-child(even) {
      font-weight: bolder;
    }
  }
  svg {
    width: 60px;
    height: 60px;
  }
}

.bottom {
  width: 100%;
  max-width: 480px;

  margin: 40px auto 65px;
  @media (min-width: $desktop) {
    margin: 0 auto -30px;
  }
}

.button {
  width: 100%;
}
.status {
  color: $secondary-red;
}
.thirBlock {
  flex: 1;
  position: relative;
}
.thirStep {
  position: absolute;
  left: 42%;
}

.userCheckBoxContainer {
  margin-top: 48px;
}

.document {
  padding: 64px 0px;
}

.checkboxContainer {
  flex: 1;
  gap: 4px;
  display: flex;
  margin-top: 24px;
  flex-direction: row;
}

.checkbox {
  font-size: 16px;
}

.checkbox:hover {
  cursor: pointer;
  color: $blue;
}
