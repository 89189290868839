@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 623px;
}

.button {
  color: $green;
  border: 2px solid $green;
  background-color: transparent;
}

.container-ticket {
  padding: 10px 16px;
  background-color: $background-light-grey-2;
}

.wrapper-ticket {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
  font-style: normal;
  line-height: 20px;
}

.ticket-title {
  font-size: 16px;
  font-weight: 600;
  color: $black;
}

.ticket-rangeDate {
  display: flex;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  color: $black;
}

.ticket-text {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.12px;
  color: $black;
}

.date {
  position: absolute;
  top: 12px;
  right: 16px;
  font-size: 14px;
  font-weight: 500;
  color: $black;
}

.ticket-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.values {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $black;
}

.wrapper-date-picker {
  display: flex;
  align-items: center;
  gap: 4px;
}