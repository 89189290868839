@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.wrapper-main {
  display: flex;
  padding-left: 16px;
  align-items: center;
  justify-content: center;
  background-color: $white;
}
