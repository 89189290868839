@import 'styles/variables';

.button {
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  height: 40px;
  padding: 8px 12px;
  text-transform: none;
  white-space: nowrap;
}
.icon {
  width: 16px;
  height: 16px;
}
.top {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 16px 24px;
  margin-bottom: 4px;
  background-color: $white;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1px;
}
.wrapperCounterparty {
  padding: 16px;
  background-color: $white;
  border-bottom: 1px solid $light-white;
}
.blockedCounterparty {
  cursor: none;
  pointer-events: none;
  background-color: $secondary-light-grey;
}
.wrapperTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    background-color: $primary-light-blue;
  }
}
.status {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.blocked {
  padding: 4px 12px;
  color: $secondary-red;
  background-color: $secondary-light-red;
}
.name {
  max-width: 70%;
  font-family: Gilroy;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.24px;
  color: $black;
}
.description {
  display: flex;
  gap: 8px;

  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.wrapperDescription {
  max-width: 70%;
  flex-wrap: wrap;
  display: flex;
  gap: 24px;
}
.reduction {
  color: $primary-grey;
}
.value {
  color: $black;
}
.invite {
  padding: 8px 12px;
  color: $brand;
  border: 2px solid $brand;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.cursorDefault {
  cursor: default;
}

.cursorPointer {
  cursor: pointer;
}

.wrapperBottom {
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $secondary-light-grey;
}
.notFound {
  display: flex;
  margin-top: 24px;
  max-width: 371px;
  min-height: 400px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.textNotFound {
  display: flex;
  flex-wrap: wrap;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  font-style: normal;
  align-items: center;
  color: $primary-grey;
  justify-content: center;
}
.wrapper-input {
  min-width: 400px;
}