@import 'styles/variables';

.wrapper-title {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $black;
}
.title-desc {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $black;
}
.wrapper-id {
  display: flex;
  align-items: center;
  gap: 8px;
}
.abbreviation {
  color: $primary-grey;
}
.wrapper-retrust {
  display: flex;
  flex-direction: column;
  gap: 4px;
  
  color: $black;
}
.title-retrust {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.16px;
  color: $primary-grey;
}
.desc-retrust {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}