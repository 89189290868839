@import 'styles/variables';

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(50, 59, 75, 0.3);
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in, background 0.3s ease-in,
  visibility 0.3s ease-in;
}

.container.visible {
  visibility: visible;
  opacity: 1;
}

.wrapper {
  position: relative;
  background: #ffffff;
  width: 100%;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.wrapper.visible {
  animation-name: slideInUp;
}

.wrapper-header {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.24px;
}

.body {
  padding: 24px;
  border-top: 1px solid $secondary-primary-grey;
  border-bottom: 1px solid $secondary-primary-grey;
}

.body.info {
  text-align: center;
}

.footer {
  display: flex;
  gap: 24px;
  padding-top: 24px;
  border-top: 1px solid #e5e5e5;
}

.footer.info {
  padding-top: 0;
  border-top: none;
}

.close-icon {
  cursor: pointer;
}

@keyframes slideInUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
