@import 'styles/variables';

.wrapper {
  max-width: 1492px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 400px;
  grid-gap: 32px;
  grid-template-areas:
  'main aside';
  transition: grid-template-columns 0.3s ease-in-out;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-areas:
    'aside'
    'main';
  }
}

.main {
  grid-area: main;
  flex: 1;
  position: sticky;
  top: 0;
  align-self: start;
  max-height: 818px;
  height: 100%;
}

.aside {
  grid-area: aside;
}

.title {
  color: $black;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.32px;
  margin-bottom: 40px;
}