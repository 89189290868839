@import 'styles/variables';

.container {
  max-width: 623px;
  width: 100%;
}
.container-authority {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.container-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  position: relative;
}
.remove {
  cursor: pointer;
}
.select-field {
  flex: 1;
}
.wrapper-footer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.button {
  height: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
  background-color: transparent;
  border: 1px solid $brand;
  color: $brand;

  path,
  rect {
    fill: $white;
  }
}
