@import 'styles/variables';

.progress-container {
  width: 100%;
  height: 4px;
  background-color: $tertiary-light-black;
  overflow: hidden;
  max-width: 388px;
}

.progress-bar {
  height: 100%;
  background-color: $secondary-green;
  transition: width 0.5s;
}
