@import 'styles/variables';

.container {
  display: flex;
  width: 100%;
  padding-top: 4px;
}
.wrapper-content {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 8px;
}
.button {
  flex: 1;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: $primary-grey;
  background-color: transparent;

  transition: all 0.2s ease-in-out;

  height: 38px;
  cursor: pointer;
}
.incoming,
.outgoing {
  color: $blue;
  background-color: $white;
}
.count {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $primary-grey;
}
.count-incoming,
.count-outgoing {
  color: $blue;
}
