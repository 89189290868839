@import 'styles/variables';

.wrapper-form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.wrapper-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.label {
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: $secondary-primary-dark-grey;
}