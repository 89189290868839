@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 623px;
}
.container-signatory {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: $secondary-primary-dark-grey;
}