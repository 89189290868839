@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.32px;
  color: $black;
}