@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.wrapper-label {
  display: flex;
  gap: 4px;
}
.label {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $primary-dark-grey;
}
.input {
  height: 40px;
  align-items: center;
  border-radius: 0px;
  max-width: 200px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.inputRange {
  min-width: 210px;
}

.info {
  display: flex;
  align-items: center;
}

.tooltip-inner {
  width: 500px;
}
