@import 'styles/variables';

.container {
  padding: 16px 24px;
  background-color: $white;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  height: 40px;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
}

.button-filter {
  background-color: $white;
  color: $brand;
  border: 2px solid $brand;
}

.search {
  min-width: 438px;
}

.wrapper-search {
  display: flex;
  gap: 12px;
}

