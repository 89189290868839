@import 'styles/variables';

.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $black;
}

.description-wrapper {
  display: flex;
  gap: 24px;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 32px;
}
.description-title {
  font-weight: 600;
  color: $dark-dark-68;
}
.description {
  font-weight: 500;
}
.reduction {
  color: $dark-dark-48;
}
.value {
  color: $black;
}
.input-wrapper {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.button-wrapper {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  padding: 24px;
}
.button {
  height: 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding: 16px;
  text-transform: none;
  height: 40px;
}
