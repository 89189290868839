@import 'styles/variables';

.container {
  display: flex;
  align-items: center;
  gap: 24px;
}

.wrapper-attr {
  display: flex;
  align-items: center;
  gap: 8px;
}

.attr {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  color: $secondary-primary-dark-grey;
}

.value {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
  color: $black;
}