@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.wrapper-label {
  display: flex;
  gap: 4px;
}
.label {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $primary-dark-grey;
}
.input {
  height: 40px;
  align-items: center;
  border-radius: 0px;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.error-input {
  border-color: $red;
}
.error {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $red;
}
.medium {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $black;
}

.wrapper-input {
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column !important;
}