@import 'styles/variables';

.root {
  display: block;
  border: none;
  text-transform: uppercase;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  transition: all 0.2s ease-in-out;

  height: 40px;
  padding: 10px;
  cursor: pointer;
}

.primary {
  background: $green;
}

.tetriary {
  background: $primary-grey;
}

.secondary {
  font-size: 20px;
  line-height: 30px;

  color: black;
  background: #f2f2f2;
  @media (min-width: $desktop) {
    background: white;
    color: #2d2eb7;
  }
}

.green {
  background: $green;
}

.disabled {
  background: #f2f2f2;
}

.icon {
  margin-top: 4px;
  background: none;
  border: none;
  img {
    display: block;
  }
  margin-right: 8px;
}

.small {
  font-size: 14px;
  height: 36px;
}

.big {
  font-size: 30px;
  line-height: 40px;
  height: 60px;
}

.outlined-tetriary {
  color: $primary-grey;
  background-color: unset;
  border: 2px solid $primary-grey;
}

.outlined-tetriary:hover {
  border: 2px solid #646e80;
  color: #646e80;

  path {
    fill: #646e80;
  }
}

.outlined-tetriary span {
  path {
    fill: $primary-grey;
  }
}

.outlined-primary {
  color: $green;
  background-color: unset;
  border: 2px solid $green;
}

.outlined-secondary {
  color: $brand;
  background-color: unset;
  border: 2px solid $brand;
}

.outlined-primary svg {
  fill: $green;
}

.cancel {
  background-color: $brand;
}
