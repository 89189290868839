.container {
  flex: 1;
  height: 64px;
  padding: 16px;
  background: #f8f9fb;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}

.leftSection,
.rightSection {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.iconWrapper {
  width: 24px;
  height: 24px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 8px;
}

.icon {
  width: 12px;
  height: 7px;
  border: 2px solid #35a388;
}

.text {
  color: #050e1e;
  font-size: 14px;
  font-family: Gilroy;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
}

.alertBox {
  padding: 4px 12px;
  background: #ffe0b1;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
}

.alertText {
  color: #fb8832;
  font-size: 14px;
  font-family: Gilroy;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
}

.imageWrapper {
  width: 32px;
  height: 32px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.innerImageWrapper {
  width: 16px;
  height: 16px;
  padding: 1.33px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 13.33px;
  height: 13.33px;
}

.disabledContainer {
  background: #f8f9fb;
  justify-content: flex-start;
  gap: 10px;
}

.disabledText {
  color: #4f6281;
}

.disabledIcon svg path{
  fill: #7f90ac;
}
