@import 'styles/variables';

.container {
  margin: 24px 0px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (max-width: $desktop) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.containerDoc {
  border: 1px solid $light-grey;
  cursor: pointer;

  &:hover {
    background-color: $primary-light-blue;
    border: 1px solid transparent;
  }
}
.wrapperDoc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  flex: 1 0 0;
}
.wrapeprLeft {
  display: flex;
  align-items: flex-start;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 12px;
  font-style: normal;
  line-height: 20px;
  white-space: nowrap;
}
.wrapperRight {
  display: flex;
  gap: 12px;
}
.name {
  max-width: 300px;
  text-wrap: wrap;
  font-size: 16px;
  font-weight: 600;
  color: $black;
}
.info {
  font-size: 14px;
  font-weight: 500;
  color: $primary-grey;
}
