@import 'styles/variables';

.main {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  padding: 16px 24px;
  background-color: $white;
  border-bottom: 1px solid $light-white;
}
.content {
  display: grid;
  grid-template-columns: minmax(300px, 495px) minmax(400px, 1fr);
  background-color: $white;
}
.footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  background-color: $white;
  border-top: 1px solid $light-white;
  gap: 12px;
}
.button {
  height: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
}
.button-back {
  background-color: $white;
  color: $brand;
  border: 2px solid $brand;
}

.form {
  gap: 16px;
  padding: 32px;
  display: flex;
  min-height: 594px;
  flex-direction: column;
  border-left: 1px solid $light-white;
}