@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  padding: 24px 0px;

  @media (min-width: 1500px) {
    flex-direction: row;
  }
}
.line {
  border-bottom: 1px solid $light-grey;
}
.wrapper-content {
  display: flex;
  flex-direction: column;
  gap: 4px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.title-section {
  color: $primary-grey;
}
.desc-section {
 color: $black;
}
.first {
  flex: 0 0 70px;
  
  @media (min-width: 1500px) {
    flex: 0 0 397px;
  }
}