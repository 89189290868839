.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 132px;
  padding: 0 100px;
}

.logo {
  padding: 10px;
  img {
    display: block;
  }
}

.menu {
  border: none;
  background: none;
}
