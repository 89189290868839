@import 'styles/variables';

.switch-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  cursor: pointer;
}
.switch-container {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 26px;
}

.switch-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  transition: 0.2s all ease-in-out;
  border: 1px solid $stroke-normal-grey;
  cursor: pointer;
}
.slider-on {
  background-color: $blue;
}
.slider-off {
  background-color: $primary-light-grey;
}

.switch-handler {
  position: absolute;
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  top: 4px;
  left: 4px;
  transition: 0.2s all ease-in-out;
  box-shadow: 0px 1px 3px 0px rgba(17, 38, 60, 0.04);
  cursor: pointer;
}
.handler-on {
  background: $white;
  transform: translateX(100%);
}
.handler-off {
  background: $white;
}
.switch-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $secondary-primary-dark-grey;
  cursor: pointer;
}