@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 8.25px;
}

.container-main {
  padding: 32px;
  background-color: $white;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid $light-white;
  padding-bottom: 24px;
}

.info-user {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.name {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.32px;
  color: $black;
}

.wrapper-action {
  display: flex;
  align-items: center;
  gap: 12px;
}

.button {
  height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
}

.container-attr {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header-main {
  display: flex;
  align-items: center;
  gap: 32px;
  padding-bottom: 24px;
  border-bottom: 1px solid $light-white;
}

.wrapper-attr {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.wrapper-main {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.wrapper-main-attr {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.main-attr {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $secondary-primary-dark-grey;
}

.main-value {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $black;
}

.container-checkbox {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.lists-checkbox {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.moreButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  background-color: #ecf3fd;
}

.permissionsActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cancelContaienr {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.button {
  text-transform: none;
}

.divider {
  margin: 24px 0px;
  height: 1px;
  background-color: $light-white;
}

.linkContainer {
  color: $green;
  text-decoration: none;
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14;
  font-family: 'Gilroy';
  font-weight: 500;
}

.linkContainer svg path {
  stroke: $green;
}
