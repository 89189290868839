@import 'styles/variables';

.wrapper-title {
  padding-right: 96px;
  display: flex;
  flex-wrap: wrap;
  word-wrap: break-word;
  align-items: flex-start;
  gap: 12px;

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.32px;
}

.title {
  color: $black;
}

.title-long {
  max-width: 600px;
}

.date {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  color: $secondary-primary-dark-grey;
}

.number {
  color: $secondary-primary-dark-grey;
}
