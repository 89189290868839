@import 'styles/variables';

.root {
  flex: 1;
  padding: 0 100px;
  display: flex;
  flex-direction: column;
}

.content {
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  @media (min-width: $desktop) {
    flex-direction: row;
  }
}

.firstBlock {
  flex: 1;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
}

.header {
  font-size: 80px;
  font-weight: lighter;
  text-transform: uppercase;
}

.subtitle {
  color: var(--color-primary-black, #050e1e);

  font-family: Gilroy;
  margin-top: 30px;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  max-width: 47%;
  letter-spacing: 0.32px;
  white-space: pre-wrap;
}

.title {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $black;
}

.restrictedText {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $black;
}


.secondBlock {
  gap: 32px;
  width: 40%;
  display: flex;
  padding: 56px;
  padding-bottom: 30px;
  height: calc(100% - 100px);
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 50px;
  right: 10%;

  background: $white;
  box-shadow: 4px 4px 56px 0px rgba(66, 66, 132, 0.15);
}

.bottom {
  width: 100%;
  max-width: 480px;
  margin: 40px auto 65px;
  @media (min-width: $desktop) {
    margin: 0 auto -114px;
  }
}

.buttonContainer {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.button {
  width: 100%;
}
