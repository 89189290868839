@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.wrapper-main {
  display: flex;
  padding: 32px;
  align-items: center;
  justify-content: center;
  background-color: $white;
}

.scroll {
  overflow-y: auto;
}

.wrapper-footer {
  padding: 16px 24px;
  background-color: $white;
}
.wrapper-button {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.button {
  display: flex;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  height: 40px;
  box-shadow: 0px 1px 3px 0px rgba(17, 38, 60, 0.04);
  text-transform: none;
}
.button-back {
  color: $brand;
  border: 1px solid $brand;
  background-color: $white;
}
