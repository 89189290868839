@import 'styles/variables';

.description {
  display: flex;
  gap: 8px;

  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.wrapperDescription {
  display: flex;
  gap: 24px;
  margin: 24px 0px;
}
.reduction {
  color: $primary-grey;
}
.value {
  color: $black;
}
.container-column {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
}
