@import 'styles/variables';

.container-document {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border: 1px solid $stroke-normal-grey;
}

.container-content {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.wrapper-content {
  display: flex;
  flex-direction: column;
}

.wrapper-title {
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: $black;
}

.size {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  color: $primary-grey;
}

.more {
  padding: 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
