@import 'styles/variables';

.timeline-item {
  position: relative;
  display: flex;

  &:hover {
    background-color: $primary-light-blue;
  }

  &:last-child {
    .timeline-content {
      &:before {
        display: none;
      }
    }
  }
}

.timeline-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  order: 1;
  padding-left: 32px;
  padding-bottom: 44px;
  gap: 6px;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    right: 100%;
    left: 16px;
    top: 7px;
    height: 100%;
    width: 2px;
    background-color: $primary-grey;
    z-index: 10;
  }

  &:after {
    content: "";
    position: absolute;
    left: 10px;
    top: 7px;
    width: 9px;
    height: 9px;
    background-color: $white;
    z-index: 1;
    border: 3px solid $primary-grey;
    border-radius: 50%;
    z-index: 11;
  }

  &:hover {
    &:after {
      border: 3px solid $blue;
    }
  }
}

.timeline-title {
  display: flex;
  align-items: center;
  gap: 8px;
}
.time {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $secondary-primary-dark-grey;
}
.timeline-desc {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $primary-grey;
}
.status {
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  font-weight: 600;
  color: $black;
}

.wrapper {
  margin: 14px 0px 14px 0px;
}
.timeline {
  padding: 10px 0px 0px 0px;
}