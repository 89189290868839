/* CheckVpiModal.module.css */

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .modalContent {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 50%; /* Adjust width as needed */
    max-width: 600px; /* Set a max-width for larger screens */
  }
  
  .modalTitle {
    margin-top: 0;
    color: #333;
    font-size: 1.5em;
  }
  
  .modalBody {
    margin-top: 20px;
    color: #666;
  }
  
  .link {
    color: blue;
    text-decoration: none;
    margin-left: 5px;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  