@import 'styles/variables';

.container {
  padding: 32px;
  background-color: $white;
}

.button {
  display: flex;
  font-size: 14px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; 
  height: 32px;
  text-transform: none;
}