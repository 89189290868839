@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  justify-content: start;
  position: relative;
}

.content-present {
  padding: 12px 16px;
  cursor: pointer;
}

.content-present:hover {
  background-color: $primary-light-blue;
}

.container-group {
  display: flex;
}

.column {
  flex-direction: column;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
  font-style: normal;
  line-height: 20px;
}

.line {
  margin: 16px 0px;
  border-bottom: 1px solid $light-grey;
}

.content-title {
  font-size: 16px;
  font-weight: 600;
  color: $black;
}

.wrapper-rangeDate {
  display: flex;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  color: $black;
}

.wrapper-text {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.12px;
  color: $black;
}

.date {
  position: absolute;
  top: 12px;
  right: 16px;
  font-size: 14px;
  font-weight: 500;
  color: $black;
}

.value {
  font-size: 14px;
  font-weight: 500;
}