@import 'styles/variables';

.section-signature-principal {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
}
.title-representative {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $black;
}
.wrapper-signature-principal {
  padding: 32px;
  margin-top: 24px;
  background-color: $secondary-light-green;
}
.wrapper-recalled {
  padding: 32px;
  margin-top: 24px;
  background-color: $tertiary-light-yellow;
}
.header-signature-principal {
  display: flex;
  align-items: center;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid $light-grey;
}
.company-signature-principal {
  display: flex;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: $black;
}
.content-signature-principal {
  display: flex;
  flex-direction: column;
  gap: 4px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.title-signature-principal {
  color: $primary-grey;
}
.desc-signature-principal {
  color: $black;
}
.footer-signature-principal {
  display: flex;
  gap: 24px;
  padding-top: 16px;
}
.description {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $black;
  margin: 24px 0px;
}