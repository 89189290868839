@import 'styles/variables';

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;

  width: 100%;
}

.button {
  height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
}

.wrapper-main-attr {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.main-attr {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $secondary-primary-dark-grey;
}

.modal-wrapper-checkbox {
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: $background-light-grey-2;
}

.wrapper-group {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.permissionsActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cancelContaienr {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.button {
  text-transform: none;
}
