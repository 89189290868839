@import 'styles/variables';

.notFound {
  display: flex;
  margin-top: 24px;
  max-width: 371px;
  min-height: 400px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.textNotFound {
  display: flex;
  flex-wrap: wrap;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  font-style: normal;
  align-items: center;
  color: $primary-grey;
  justify-content: center;
}

.notFoundContainer {
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $secondary-light-grey;
}
