@import 'styles/variables';

.main-header {
  padding: 16px 16px;
  background-color: $white;
  border-bottom: 1px solid $secondary-light-black;
}
.custom-select {
  max-width: 461px;
}
