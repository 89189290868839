@import 'styles/variables';

.wrapper-name {
  display: flex;
  align-items: center;
  gap: 6px;

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.32px;
  color: $black;
}

.wrapper-attributes {
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  color: $primary-dark-grey;

  display: flex;
  align-items: center;
  gap: 16px;
}
.attribute-item {
  display: flex;
  align-items: center;
  gap: 8px;
}