@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 11px;
  padding: 16px 24px;
  background-color: $white;
}
.content {
  padding: 32px;
  background-color: $white;
}

.button {
  font-size: 16px;
  text-transform: none;
}
.button-download path {
  font-size: 14px;
  fill: $green;
}
