@import 'styles/variables';

.container-documents {
  margin-top: 24px;
}

.grid {
  gap: 16px;
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));

  @media (max-width: $desktop) {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
