@import 'styles/variables';

.container {
  display: flex;
  gap: 48px;
  padding: 24px 0px;
}
.wrapper-procedure {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.wrapper-term {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.description {
  display: flex;
  flex-direction: column;
  gap: 4px;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; 
}
.title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; 
  color: $secondary-primary-dark-grey;
}
.wrapper-title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.title-term {
  white-space: nowrap;
  color: $secondary-primary-dark-grey;
}
.date {
  color: $black;
}


.container-total {
  display: flex;
  flex-direction: column;
  border-left: 1px solid $secondary-light-black;
  width: 100%;
}
.wrapper-discount {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 24px;
}
.title-discount {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: $secondary-primary-dark-grey;
}
.content-discount {
  display: flex;
  flex-direction: column;
}
.discount {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  color: $dark;
}
.description-discount {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.12px;
  color: $primary-grey;
}
.line {
  margin: 24px 0px;
  border: 0.5px solid $secondary-light-black;
}
.wrapper-total {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 24px;
}
.title-total {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; 
  color: $secondary-primary-dark-grey;
}
.total {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  color: $dark;
}
.progress {
  max-width: 388px;
  border-radius: 0px  ;
}