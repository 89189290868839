@import 'styles/variables';

.upload-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $black;
}
.upload-hint {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.12px;
  color: $primary-grey;
}

.wrapper-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.button {
  height: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
  background-color: $white;
  color: $brand;
  border: 2px solid $brand;
}
