@import 'styles/variables';

.root {
  flex: 1;
  padding: 0 100px;
  display: flex;
  flex-direction: column;
}

.container {
  margin: 56px 64px 64px;
}
