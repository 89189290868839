@import 'styles/variables';
.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  background: $white;
  min-width: 500px;
  border-bottom: 1px solid $light-grey;
}

.header {
  display: flex;
  position: relative;
}

.status {
  width: max-content;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  white-space: nowrap;
  padding: 4px 12px;
  background-color: $secondary-light-green;
  color: $light-green;
  height: 24px;
}

.status-red {
  background-color: $secondary-red;
  color: $white;
}

.description {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.wrapperName {
  display: grid;
  grid-template-columns: 1fr minmax(170px, 1fr);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.24px;
  color: $black;
  gap: 10px;
}
