@import 'styles/variables';

.container {
  padding: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid $secondary-light-black;
  }
}
.wrapper-payment {
  padding: 24px;
  cursor: pointer;

  &:hover {
    background-color: $primary-light-blue;
  }
}
.content-payment {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.title {
  display: flex;
  align-items: center;
  gap: 12px;

  font-size: 16px;
  font-style: normal;
  line-height: 20px;
}
.title-text {
  font-weight: 600;
  color: $black;
}
.title-time {
  font-weight: 500;
  color: $secondary-primary-dark-grey;
}
.description {
  font-size: 14px;
  font-weight: 500;
  color: $primary-grey;
}
