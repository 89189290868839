@import 'styles/variables';

.container {
  padding: 16px;
  background-color: $white;
}
.wrapper-card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  cursor: pointer;

  &:hover {
    background-color: $primary-light-blue;
  }
}

.current {
  background-color: $primary-light-blue;
}

.date {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $primary-grey;
}
.wrapper-user {
  display: flex;
  flex-direction: column;
}
.recalled-user {
  margin: 12px 0px 8px 0px;
}
.name {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $black;
}
.range {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $black;
}
.recipient {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.12px;
  color: $primary-grey;
}
.company {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: $black;
}
.id {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $black;
}
