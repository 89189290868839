@import 'styles/variables';

.container {
  padding: 16px;
  background-color: $white;
  cursor: pointer;
  
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding: 16px;

  &:hover {
    background-color: $primary-light-blue;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.wrapper-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.24px;
  color: $black;
}

.middle-attr {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: $secondary-primary-dark-grey;
}

.container-attr {
  display: flex;
  align-items: center;
  gap: 24px;
}

.wrapper-attr {
  display: flex;
  align-items: center;
  gap: 8px;
}

.attr {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  color: $secondary-primary-dark-grey;
}

.value {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
  color: $black;
}