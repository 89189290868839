@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.input {
  border: none;
  border-radius: 0px;
  height: 40px;
}

.scroll-container {
  overflow: hidden;
}
.scroll {
  flex: 1 1 auto;
  max-height: 700px;
  overflow-y: auto;
  scrollbar-width: thin;

  scrollbar-color: red;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $light-grey;
    border-radius: 8px;
  }
}
.user-container {
  padding: 16px;
  background-color: $white;
}
.user-content {
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 16px;
  cursor: pointer;
  &:hover {
    background-color: $primary-light-blue;
  }
}
.user-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.date {
  color: $primary-grey;
}
.status {
  padding: 4px 12px;
  color: $light-green;
  background-color: $secondary-light-green;
}
.user-main {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $black;
}
.description {
  display: flex;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.user-footer {
  display: flex;
  gap: 24px;
}
.reduction {
  text-transform: uppercase;
  color: $primary-grey;
}
.value {
  color: $black;
}
.container-empty {
  margin-top: 4px;
  padding: 32px;
  background-color: $tertiary-light-grey;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-empty {
  color: $primary-grey;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}
