@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.title {
  color: $black;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.32px;
}

.wrapper-content {
  display: flex;
  flex-direction: column;
}

.content-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.headerContainer {
  margin: 40px 0px;
}

.wrapper-main {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.notFoundContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
