@import 'styles/variables';

.button-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  padding: 24px;
}
.button {
  height: 40px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: none;
}
.cancel {
  background-color: $white;
  color: $brand;
  border: 2px solid $brand;
}
.body {
  overflow: hidden;
  overflow-y: auto;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.wrapper-info {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}
.text-info {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.16px;
  color: $dark-dark-48;
}
.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.description-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $secondary-primary-dark-grey;
}