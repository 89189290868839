@import 'styles/variables';

.container {
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  background-color: $white;
}
.title {
  color: $blue;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.32px;
  text-transform: uppercase;

}
.line {
  height: 1px;
  padding-top: 16px;
  border-bottom: 1px solid $light-grey;
}
.newEvents {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}