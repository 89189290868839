@import 'styles/variables';

.container {
  position: relative;
}
.tags-count {
  position: absolute;
  right: 38px;
  bottom: 8.5px;
  padding: 2px 4px;
  color: $white;
  background-color: $secondary-green;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.12px;
}