@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.input {
  padding: 4px 12px;
  border-radius: 0px;
  height: 40px;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.scroll-container {
  overflow: hidden;
}
.scroll {
  display: flex;
  flex-direction: column;
  gap: 1px;

  flex: 1 1 auto;
  max-height: 700px;
  overflow-y: auto;
  scrollbar-width: thin;

  scrollbar-color: red;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $light-grey;
    border-radius: 8px;
  }
}