@import 'styles/variables';

.container {
  height: 100%;
  padding: 16px;
  background-color: $white;
}
.wrapper {
}
.footer {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.button {
  display: flex;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  height: 40px;
  box-shadow: 0px 1px 3px 0px rgba(17, 38, 60, 0.04);
  text-transform: none;
}
.button-back {
  color: $brand;
  border: 1px solid $brand;
  background-color: $white;
}
