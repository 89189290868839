@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.header-checkbox {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.lists-checkbox {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.container-checkbox {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: $background-light-grey-2;
}

.wrapper-desc {
  display: flex;
  align-items: center;
  gap: 16px;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.12px;
}

.info {
  color: $dark-dark-48;
}

.action {
  color: $brand;
  cursor: pointer;
}