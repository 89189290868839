@import 'styles/variables';

.wrapperTop {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 40px;
}
.title {
  display: flex;
  justify-content: space-between;
}
.name {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; 
  letter-spacing: 0.32px;
  color: $black;
}
.date {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  color: $primary-dark-grey;
}
.idUser{
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $primary-dark-grey;
}
.content {
  padding: 32px;
  background-color: $white;
}
.button {
  height: 32px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; 
  text-transform: none;
}
