@import 'styles/variables';

.root {
  flex: 1;
  padding: 0 100px;
  display: flex;
  flex-direction: column;
}

.content {
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  @media (min-width: $desktop) {
    flex-direction: row;
  }
}

.firstBlock {
  flex: 1;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
}

.header {
  font-size: 80px;
  font-weight: lighter;
  text-transform: uppercase;
}

.entranceContainer {
  width: 50%;
  display: flex;
  padding-right: 132px;
  align-items: flex-end;
  justify-content: flex-end;
}

.entrance {
  font-size: 60px;
  margin-top: 38px;
  font-weight: bold;
  color: $brand;
  & > * {
    display: inline-block;
  }
  svg {
    width: 68px;
    height: 68px;
    margin-left: 18px;
  }

  display: none;
  @media (min-width: $desktop) {
    display: flex;
  }
  @media (max-height: 764px) {
    font-size: 50px;
    margin-top: 30px;
    svg {
      width: 50px;
      height: 50px;
      margin-left: 18px;
    }
  }
  @media (max-height: 730px) {
    font-size: 30px;
    margin-top: 20px;
    svg {
      width: 30px;
      height: 30px;
      margin-left: 18px;
    }
  }
  @media (max-height: 700px) {
    display: none;
  }
  align-items: center;
}

.subtitle {
  color: var(--color-primary-black, #050e1e);

  font-family: Gilroy;
  margin-top: 30px;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  max-width: 47%;
  letter-spacing: 0.32px;
  white-space: pre-wrap;
}

.description {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $black;
}

.secondBlock {
  gap: 32px;
  width: 40%;
  display: flex;
  padding: 56px;
  padding-bottom: 30px;
  height: calc(100% - 100px);
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 50px;
  right: 10%;

  background: $white;
  box-shadow: 4px 4px 56px 0px rgba(66, 66, 132, 0.15);
}

.text {
  font-size: 20px;
  line-height: 40px;
  margin-top: 40px;
  max-width: 756px;
}

.selector {
  margin-top: 40px;
}

.bottom {
  width: 100%;
  max-width: 480px;
  margin: 40px auto 65px;
  @media (min-width: $desktop) {
    margin: 0 auto -114px;
  }
}

.buttonContainer {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.full-width {
  width: 100%;
}

.button {
  width: 100%;
}

.recoverButton {
  margin-top: 27px;
  background: #f2f2f2;
  @media (min-width: $desktop) {
    background: white;
  }
}
