@import 'styles/variables';

.alert-container {
  width: 300px;
  margin-bottom: 57px;
  padding: 20px 32px;
  pointer-events: all;
  
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  &.error {
    border: 1px solid $secondary-red;
    background-color: $secondary-red;
  }
  
  &.warning {
    border: 1px solid $secondary-red;
    background-color: $secondary-red;
  }
  
  &.success {
    color: $white;
    border: 1px solid $light-green;
    background-color: $light-green;
  }
}

.content-container {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.text-container {
    white-space: break-spaces;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  height: 100%;
}

.icon-close {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;

  & > button {
    margin: 0 20px 20px 0;
  }
}
