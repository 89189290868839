@import 'styles/variables';

.custom-accordion {
  width: 100%;
}

.bordered .accordion-panel {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
}

.accordion-panel {
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordion-panel.active {
  max-height: 500px;
  transition: max-height 0.3s ease;
}

.panel-header {
  padding: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $black;
  background-color: $background-light-grey-2;
}

.panel-content {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 24px;
  max-height: 100px;
  background-color: $background-light-grey-2;
  transition: max-height 0.3s ease opacity 0.3s ease;
}

.expand-icon {
  font-size: 18px;
  margin-right: 10px;
}

.hidden {
  min-height: 0px;
  opacity: 0;
  display: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.12px;
  transition: max-height 0.3s ease, opacity 0.3s ease ;
}

.icon {
  display: flex;
  align-items: center;
  gap: 8px;
}

.content {
  color: $black;
}

.size {
  color: $secondary-primary-dark-grey;
}

.view {
  color: $brand;
}

.wrapper-info {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;

  &::before {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50px;
    background-color: $primary-grey;
    position: absolute;
    left: 53px;
    transform: translateX(-50%);
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}