@import 'styles/variables';

.root {
  flex: 1;
  margin-top: -20px;
  padding: 0 100px;
  display: flex;
  flex-direction: column;
}

.content {
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  @media (min-width: $desktop) {
    flex-direction: row;
  }
}

.firstBlock {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.header {
  font-size: 80px;
  font-weight: lighter;
  margin-bottom: 40px;
  text-transform: uppercase;

  display: none;
  @media (min-width: $desktop) {
    display: block;
  }
}

.title {
  font-size: 40px;

  font-weight: normal;
  text-transform: none;
  @media (min-width: $desktop) {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.subtitle {
  margin-top: 40px;
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  text-transform: uppercase;
}

.secondBlock {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.stepIndicator {
  display: flex;
  position: absolute;
  top: -65px;
  left: 0;

  visibility: hidden;
  @media (min-width: $desktop) {
    visibility: visible;
  }
}

.step {
  color: #e5e5e5;

  display: flex;
  align-items: center;
  font-size: 57px;
  line-height: 40px;
  font-weight: bolder;
  svg {
    width: 34px;
    height: 28px;
    margin: 0 15px 0 11px;
  }
}

.stepFilled {
  color: $brand;
}

.selector {
  margin-top: 40px;
  @media (min-width: $desktop) {
    margin-top: auto;
    order: 1;
  }
}

.text {
  font-size: 20px;
  line-height: 30px;
  margin: 90px auto 0;
  max-width: 480px;
  @media (min-width: $desktop) {
    margin: 60px 0 0;
    max-width: 510px;
  }
}

.bottom {
  width: 100%;
  max-width: 480px;

  margin: 40px auto 65px;
  @media (min-width: $desktop) {
    margin: 0 auto -30px;
  }
}

.button {
  width: 100%;
}

.input {
  width: 100%;
  height: 46px;
  border-radius: 0px;
  text-transform: uppercase;
}