@import 'styles/variables';

.root {
  z-index: -1;

  margin-top: auto;
  background-image: url('/dynamic/assets/footer.jpg');
  background-size: cover;
  background-repeat: no-repeat;

  height: 300px;
  background-size: 160%;
  @media (min-width: $desktop) {
    height: 160px;
    background-size: 100%;
  }
}
