@import 'styles/variables';

.checkbox-label {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 0.2 linear;

  &.block {
    height: 40px;
    padding: 0 12px;
    border-radius: 0px;
    pointer-events: none;

    &:hover, &:active {
      background-color: red;
      border-color: red;
    }

    &.checked {
      background-color: blue;
      border-color: blue;
    }

    &.disabled {
      background-color: black;
      border-color: black;
    }
  }
}

.checkbox-mark {
  position: relative;
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 0px;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: 2px solid $blue;
  margin-left: 3px;
  margin-right: 16px;

  .checkbox-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &.checked {
    background-color: $blue;
    border-color: $blue;

    .checkbox-icon-container > svg {
      opacity: 1;
      transition: opacity 0.2s linear;

      path, rect {
        fill: $white;
      }
    }
  }
  &:not(.checked) {
    background-color: $white;
    border-color: $blue;

    .checkbox-icon-container > svg {
      opacity: 1;
      transition: opacity 0.2s linear;

      path, rect {
        fill: $white;
      }
    }
  }
}

.checkbox-text {
  display: flex;
  flex: 1 1 100%;
  cursor: pointer;
  user-select: none;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  -webkit-appearance: none;
  appearance: none;

  &:checked + .checkbox-mark {
    background-color: $blue;
    > svg {
      opacity: 1;

      path, rect {
        fill: $white;
      }
    }
  }

  &:hover:not(:checked, :disabled) + .checkbox-mark {
    border: 2px solid $blue;

    > svg {
      opacity: 1;

      path, rect {
        fill: $blue;
      }
    }
  }

  &:active:not(:checked, :disabled) + .checkbox-mark {
    border: 2px solid $blue;

    > svg {
      opacity: 1;

      path, rect {
        fill: $blue;
      }
    }
  }

  &:disabled {
    & ~ .styled-icon {
      cursor: initial;

      > svg path, > svg rect {
        fill: $blue;
      }
    }

    & ~ .checkbox-text {
      cursor: initial;
      color: $blue;
    }

    & ~ .checkbox-mark {
      cursor: initial;
      border-color: $blue;

      > svg path, > svg rect {
        fill: $blue;
      }
    }

    &:checked ~ .checkbox-mark {
      cursor: initial;
      background-color: $blue;
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}