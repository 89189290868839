@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.wrapper-main {
  display: flex;
  background-color: $white;
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
}
