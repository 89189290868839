.dropdownContainer {
  position: relative;
}

.dropdown {
  cursor: pointer;
}

.dropdown.open + .dropdownContent {
  display: block;
  position: absolute;
  top: 140%;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
  animation: 0.2s slide;
}

.dropdown.open svg {
  transition: transform 0.2s ease-in-out;
  transform: rotate(180deg);
}

.dropdown svg {
  transition: transform 0.2s ease-in-out;
}

.dropdownContent {
  display: none;
}

.footer {
  margin: 4px 12px 6px 12px;
}

@keyframes slide {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}