@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.wrapper-label {
  display: flex;
  gap: 4px;
}
.label {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $primary-dark-grey;
}