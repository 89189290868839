@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.wrapper-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: $secondary-primary-dark-grey;
}