@import 'styles/variables';

.container {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'sidenav navbar'
    'sidenav main';
  min-height: 100vh;
  transition: grid-template-columns 0.3s ease-in-out;
}

.main {
    grid-area: main;
    background-color: $dashboard-main-bg;
}