.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 623px;
}
.container-signatory {
  display: flex;
  flex-direction: column;
  gap: 12px;
}