@import 'styles/variables';

.wrapper_contracts {
  display: flex;
}
.wrapper_powersofattorney {
  display: flex;
}
.wrapper_counterparties {
  display: flex;
}
.wrapper_organizations {
  display: flex;
}

.wrapper {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  // padding-right: 16px;

  &:hover {
    background-color: $dashboard-item-background-hover;
  }
}
.active {
  color: $dashboard-item-text;
  background-color: $dashboard-item-background-hover;
}
.link {
  display: flex;
  padding: 16px;
  display: block;
  color: $deep-grey;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
  flex: 1 1 100%;

  &:hover {
    color: $dashboard-item-text;
  }
}
.activeLink {
  color: $dashboard-item-text;
}
.subMenu {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 48px;
  width: 100%;
}

.inactive {
  color: $dashboard-item-text-inactive;
}

.subLink {
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  position: relative;
  padding: 8px 12px;

  &:last-child {
    &:after {
      content: '';
      position: absolute;
      inset-inline-start: 15px;
      top: -2px;
      left: -20.5px;
      height: 18px;
      width: 18px;
      border-inline-start: 2px solid $dashboard-item-text;
      border-bottom: 2px solid $dashboard-item-text;
      background: transparent;
      border-end-start-radius: 10px;
    }
  }

  &:hover {
    color: $dashboard-item-text;
  }
}

.line {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -21px;
    top: 0;
    bottom: -16px;
    width: 2px;
    background: $dashboard-item-text;
  }
}

.wrapperSubLink {
  margin-top: 2px;

  &:hover {
    background-color: $dashboard-item-background-hover;
  }
}

.contentHidden {
  overflow: hidden;
  opacity: 0;
  line-height: 1.55;
  pointer-events: none;
  height: 0;
  transition: opacity 0.1s, height 0.1s;
}

.contentVisibled {
  opacity: 1;
  height: max-content;
  transition: opacity 0.2s, height 0.2s;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.icon {
  margin-left: 16px;
  width: 24px;
  height: 24px;
}

.icon-selected path {
  stroke: $dashboard-item-text;
}

.icon-inactive path {
  stroke: $dashboard-item-text-inactive;
}

.icon-selected path:hover {
  stroke: $dashboard-item-text;
}

.icon-selected path {
  stroke: $dashboard-item-text;
}
